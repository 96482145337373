/**
	Body 
*/

body,body * {
	margin: 0;
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 14px;
	font-weight: 300;
}

p {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em;
	font-weight: 300;
	letter-spacing: normal;
	line-height: 1rem;
}

table.table {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em;
	font-weight: 300;
	letter-spacing: normal;
	line-height: 1rem;
}


/**
	Colors 
*/

.emersonprimary{
	color: #00AA7E
}


/**
	Headers 
*/

.emerson-logo {
	padding: 0px 7px 0px 135px;
}

img.logo {
	height: 60px;
}

nav.navbar.navbar-expand-md.navbar-light.bg-light {
	box-shadow: 0 1px 7px rgba(0, 0, 0, .3);
}

.heading-row.row {
	padding-top: 30px;
}

p.main-head {
	font-size: 1.250em;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1em;
	padding: 0px 0px 0px 14px;
}

.tab-pane.active {
	border-bottom: 1px solid #cccc;
	border-right: 1px solid #ccc;
	border-left: 1px solid #ccc;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	color: #00AA7E !important;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff;
	text-transform: uppercase;
	font-weight: 400;
}

.nav-tabs .nav-link {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	border: 1px solid transparent;
	border-top-left-radius: 0.25em;
	border-top-right-radius: 0.25em;
	background-color: #F0F0F0;
	border-radius: inherit;
	text-transform: uppercase;
	border-right: 2px solid #dee6ea !important;
	cursor: pointer;
}

.nav-link:hover {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	color: #00AA7E !important;
}

.row-tab.row {
	padding: 20px 50px;
}

.nav-tabs .nav-link {
	border-top-left-radius: 0em !important;
	border-top-right-radius: 0em !important;
}

/**
	Custom Css
*/

.nav-link h2, .instruction h2, .row-tab h2 {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: .875em ;
	font-weight: 600 ;
	line-height: 1.89em ;
	letter-spacing: .04em ;
	text-transform: uppercase;
}

/**
*	Custom Css
*/

.form-control::placeholder{
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em ;
}
.form-control{
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	display: inline-block ;
	border-radius: 0px ;
	background: #F0F0F0 ;
	font-size: 0.875em ;
	line-height: 1.625em ;
	border: 2px solid #f0f0f0 ;
	padding: 0.275rem 0.55em ;
	height: 50px;
}


.form-control:hover {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	border: 2px solid #00AA7E !important;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.form-control:focus {
	border: 2px solid #00AA7E !important;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	box-shadow: none !important;
	/* box-shadow: 0 0 0 0.2rem rgba(0, 170, 126, 0.25) !important; */
}
.form-input-text.form-control.active {
    border: 2px solid #000 !important;
}


.form-control.text-filter, .form-control.number-filter-comparator, .form-control.number-filter-input,.form-control.date-filter-comparator,.form-control.date-filter-input {
	height: 28px !important;
	border: 1px solid #000 !important;

}
.form-control.number-filter-comparator, .form-control.date-filter-comparator{
	width: 50px !important ;
}
.form-control.date-filter-input{
	width: 130px !important;
}
.form-control.number-filter-input{
	width: 50px !important;
}

.form-control.text-filter:focus, .form-control.number-filter-comparator:focus, .form-control.number-filter-input:focus,.form-control.date-filter-comparator:focus,.form-control.date-filter-input:focus,.form-control.date-filter-input:focus {
	border: 1px solid #00AA7E !important;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	box-shadow: none !important;
}
.form-control.text-filter:hover, .form-control.number-filter-comparator:hover, .form-control.number-filter-input:hover,.form-control.date-filter-comparator:hover,.form-control.date-filter-input:hover,.form-control.date-filter-input:hover {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	border: 1px solid #00AA7E !important;
	-webkit-border-radius: 0;
	border-radius: 0;
}
.was-validated .form-control:valid,
.form-control.is-valid {
	border: 2px solid #00AA7E !important;
	background-image: none !important;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
	background-image: none !important;
}
.form-control.active.is-valid {
	border: 2px solid #00AA7E !important;
	background-image: none !important;
}
input.form-input-text.date-type.is-valid {
	border: 2px solid #00AA7E !important;
	background-image: none !important;
}
input.form-input-text.date-type.is-invalid {
	border: 2px solid #d31245 !important;
	background-image: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border: 2px solid #d31245!important;
}
.form-control.active.is-invalid {
	border: 2px solid #d31245!important;
}

form.row-tab {
	padding: 20px 0 0 0;
}

.form-dropdown-text {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

.form-dropdown-text:hover {
	border: 2px solid #00AA7E !important;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.form-dropdown-text:focus {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}
.form-control:disabled, .form-control[readonly] {
    background-color: #C4CED1;
	opacity: 1;
	border: none !important;
}

label.select-drop-label:after {
	content: "\f078";
    font-family: "FontAwesome";
    font-size: 12px;
    color: #2D383F;
    right: 30px;
    top: 45px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}
label.select-drop-label-main:after {
	content: "\f078";
    font-family: "FontAwesome";
    font-size: 12px;
    color: #2D383F;
    right: 30px;
    top: 32px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}

.react-datepicker-wrapper {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	width: 95% !important;
}

.react-datepicker__input-container {
	width: 100% !important;
}

input.form-input-text.date-type {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
    width: 100% !important;
    height: 35px !important;
    box-sizing: content-box;
    border-radius: 0px !important;
    /* color: #C0C0C0 !important; */
    background: #F0F0F0 !important;
    /* font-size: 1rem !important; */
    line-height: 1.625em !important;
    border-style: none !important;
    border: 2px solid #f0f0f0 !important;
	}
input.datepicker-input.form-input-text.date-type:hover {
	border: 2px solid #00AA7E !important;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	box-shadow: none !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}
input.datepicker-input.form-input-text.date-type:focus{
	border: 2px solid #00AA7E !important;
	-webkit-border-radius: 0;
	border-radius: 0;
	outline:none !important;
}

label.custom-label {
	text-transform: uppercase;
    font-weight: 600;
    font-size: .75em;
    letter-spacing: .08em;
    display: block;
    margin-bottom: 7px;
	line-height: .75em;
	margin-top: 10px;
}

small.custom-text.form-text.text-muted {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: .75rem;
	line-height: 1.25em;
}

.visibilty {
	visibility: hidden;
}

.search-for {
	padding: 43px 0px 0px 30px;
	cursor: pointer;
	font-size: .75rem;
	font-weight: 600;
	line-height: .875rem;
	margin-bottom: .4375rem;
	color: #00AA7E;
}

.search-for:hover {
	cursor: pointer;
	color: #00AA7E;
	text-decoration: underline;
	font-size: .75rem;
	font-weight: 600;
	line-height: .875rem;
	margin-bottom: .4375rem;
}

.tab-content {
	padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	[class*="col-"] {
		width: 100%;
	}
	.row-tab.row {
		padding: 20px 14px;
	}
	.customer.row {
		padding: 20px 26px 0px 6px;
	}
}

@media only screen and (max-width: 667px) {
	.language-item.nav-item{
	 margin: 0px 0px 8px 0 !important; 
	}
}
button.btn.btn-secondary.primary-btn {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	background-color: #00AA7E;
	cursor: pointer;
	color: #ffffff;
	font-size: 0.875rem;
	padding: 13px 22px;
	text-decoration: none;
	border-style: none;
	border-radius: unset;
	font-weight: 500;
	text-transform: uppercase;
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	letter-spacing: 0.08rem;
	height: 50px;
}

button.btn.btn-secondary.secondary-btn {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	background-color: #fff;
	cursor: pointer;
	color: #2d383f;
	font-size: 0.775rem;
	padding: 13px 22px;
	text-decoration: none;
	border: 1px solid #2d383f;
	border-radius: unset;
	font-weight: 600;
	text-transform: uppercase;
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	letter-spacing: 0.08rem;
	height: 50px;
}

button.btn.btn-secondary:hover {
	background-color: #016f53 !important;
}

button.btn.btn-secondary.secondary-btn:hover {
	background-color: #c4ced1 !important;
	color: #2d383f;
	border: none;
}

button.btn.btn-secondary .hvr-icon{
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	font-size: 12px;
	
  }


button.btn.btn-secondary:hover .hvr-icon, 
.button.btn.btn-secondary:focus .hvr-icon, 
.button.btn.btn-secondary:active .hvr-icon{
	-webkit-transform: translateX(4px);
	transform: translateX(4px);
}


  
button.button-padding.btn-block.btn-secondary {
	background: #00AA7E;
	border-style: none;
	font-size: .875rem;
	line-height: 1rem;
	color: #ffffff;
	padding: 15px 0px 15px 0px;
}

.btn-secondary:focus {
	box-shadow: none !important;
}



.icon-style {
	font-size: 12px;
}


.button-style {
	padding: 0px 0px 0px 12px !important;
	margin-top: 25px !important;
}
.button-style1 {
	padding: 0px 0px 0px 12px !important;
}

.custom-margin {
	margin: 0px 0px 0px 18px;
}

.top-margin-input {
	margin-top: 23px;
}



.radio-button {
	position: relative;
	padding-left: 30px;
	margin-bottom: .9375rem;
	cursor: pointer;
	font-size: 0.875em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


/* Hide the browser's default radio button */

.radio-button input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: #C0C0C0;
	/* border: 2px #C0C0C0; */
	border-radius: 50%;
}

.radio-button-label {
    margin-bottom: .9375rem;
    /* font-size: 1rem;
    line-height: 1rem; */
    padding-top: 0px !important;
}


/* On mouse-over, add a grey background color */

.radio-button:hover input~.checkmark {
	background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.radio-button input:checked~.checkmark {
	background-color: #C0C0C0;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}


/* Show the indicator (dot/circle) when checked */

.radio-button input:checked~.checkmark:after {
	display: block;
}


/* Style the indicator (dot/circle) */

.radio-button .checkmark:after {
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #00AA7E;
}

.background-strip.row {
	background-color: #dee6ea;
	height: 75px;
	margin: 20px 0 0 0;
}

.pad-tab-container {
	margin-top: -40px;
}

.img-padding {
	padding-top: 32px;
}

@media (max-width: 767px) {
	.hidden-xs {
		display: none!important;
	}
	.img-padding {
		padding-top: 10px;
		margin-left: 30px;
	}
	.pad-tab-container {
		margin-top: 0px;
	}
	.tab-mobile-screen{
		display: block !important;
		
	}
	.desktop-tab{
		display: none !important;
	}
	.tab-pane.active{
		border-top: 1px solid #cccc !important;
	}
	.search-for{
		padding: 5px 0px 0px 0px !important;
	}
	button.btn-secondary-cta.btn-arrow-secondary.btn-block.btn.btn-secondary{
		width: 102% !important;
	}
	button.btn-secondary-cta.btn-arrow-secondary.btn-block.btn.btn-secondary.serial {
		width: 106% !important;
	}
	.cutomer.row {
		padding: 0px 30px !important;
	}
	
	
}

.instruction p {
	font-size: 12px;
	text-align: justify;
}

.tab-mobile-screen{
	display: none
}


.badge-primary {
    color: #fff;
    background-color: #00AA7E !important;
    padding: 6px 15px 6px 15px !important;
    cursor: pointer;
}
.badge-secondary {
    padding: 6px 15px 6px 15px !important;
    
}
#loader{
	left : 47%;
    top : 50%;
    position : absolute;
    z-index : 101;
}
.loader {
	width: 100%;
    background-color: #000;
    position: fixed;
    z-index: 9;
    opacity: 0.4;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.col-sm-12.activation-type {
    border: 1px solid #000;
	padding: 15px 15px;
	margin: 0 0px 30px 0;
}
#comments{
	height: 107px;;
}
.activation-type .row.form-group {
    padding: 0px 15px 0px 0px;
}
.cutomer.row {
    padding: 0px 50px;
}
.react-bootstrap-table {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch !important;
}
.right{
	right: 9px;
}
td.react-bs-table-no-data {
    text-align: center;
}
.activate-button{
	width: 107% !important;
	margin: 20px 0 0 0 ;
}

.selectToday {
    background: #00AA7E !important;
	text-transform: capitalize;
	border-radius: 0px !important;
}
.today button {
    border: 3px solid #00AA7E!important;
}
.calendarContainer .selected button, .calendarContainer .selected button:active, .calendarContainer .selected button:focus, .calendarContainer .selected button:hover{
	background-color: #00AA7E !important;
}
.calendarContainer .heading .title{
	border: 2px solid #00AA7E !important;
}
input.datepicker-input {
    display: none;
}
label.calender-icon {
	position: relative;
    margin: -37px 17px 0 0;
    float: right;
    cursor: pointer;
}
label.calender-icon:hover {
    color: #00AA7E !important;
}
.toast.fade.show {
    position: absolute;
    margin: 79px 0px 0px 360px;
}
.dayWrapper.currentMonth.today button {
    border: 3px solid #00AA7E !important;
}
.calendarContainer .dayWrapper button:hover {
    background-color: #00AA7E;
    color: #FFFFFF;
}
span.edit {
    cursor: pointer;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none !important;
}
.page-link:hover {
    z-index: 2;
    color: #00AA7E !important;
    text-decoration: none;
    background-color: #ffffff !important ; 
	border-color: #ffffff !important;
	text-decoration: underline;
}
.page-link {
    position: relative;
    display: block;
    padding: 1.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #000 !important;
    background-color: #ffffff !important; 
	border: #ffffff !important;
	font-size: 16px;
}
.page-item.active .page-link {
    z-index: 1;
    color: #00AA7e !important;
    background-color:#ffffff !important; 
    border-color: #ffffff !important;
}

.react-bootstrap-table-pagination-list{
	margin-left: -120px !important;
}
@media only screen and (max-width: 600px) {
	.react-bootstrap-table-pagination-list{
		margin-left: 0px !important;
	}
  }
table.table.table-borderless.update-table td {
    padding: 3px 2px 2px 4px;
}
.export-csv.pull-right {
    margin: 0 -14px 5px 0px;
}
.month-selector .heading .prev {
	float: left !important;
	transform: rotateZ(180deg) !important;
}
.month-selector .heading .next {
	float: right !important;
	transform: rotateZ(180deg) !important;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #00AA7E !important;
    opacity: 1 !important;
    cursor: no-drop!important;
}

button.visibility.btn.btn-primary.active {
    background: #00AA7E;
    border: 1px solid #00AA7E;
    border-radius: 0px;
    font-size: 10px;
    padding: 6px 6px 6px 6px;
    margin: 0px 5px 9px 2px;
}
button.visibility.btn.btn-primary{
    background: #ffffff;
    border: 1px solid #000;
    border-radius: 0px;
    font-size: 10px;
    padding: 6px 6px 6px 6px;
    margin: 0px 5px 9px 2px;
    color: black;
}
button.visibility.btn.btn-primary:focus{
    box-shadow: none;
}
button.visibility.btn.btn-primary:hover{
	background: #00AA7E;
	border: 1px solid #00AA7E;
	color: #ffffff;
}
/* button.visibility.btn.btn-primary.active {
    background: #ffffff;
	border: 1px solid #000;
	color: #000;
} */

/* Table Css */

.table-bordered {
    border: 1px solid #000000 !important ;
}
.table thead th {
	border-bottom: 2px solid #000000 !important ;
	font-weight: 600;
	background: #006998;
	color: #F0F0F0;
	vertical-align: bottom !important;
}
.table-bordered th, .table-bordered td {
    border: 1px solid #000000 !important;
}
.table th, .table td {
	padding: 5px 5px !important;
	font-size: 11px !important;
}
.filter.number-filter {
    display: table !important;
    table-layout: fixed !important;
	/* margin: 0px 0px 8px 0 !important ; */
}
.filter.number-filter label {
    display: table-cell !important;
    width: 100px !important;
}
.filter.date-filter{
	display: table !important;
    table-layout: fixed !important;
	/* margin: 0px 0px 8px 0 !important ; */
} 
.filter.date-filter label{
	display: table-cell !important;
	
}
table.update-table td {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em !important ;
}
tbody.display-table td{
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em !important ;
}
.calendarContainer .monthsList button{
	float: left !important;
}
.showactivepanel {
	display: block;
}
.hideactivepanel{
	display: none;
}

a.login-link.nav-link {
    color: #ffffff !important;
    padding: 0;
}

.language-item.nav-item {
    margin: 2px 30px 0 0;
}
.language-item p{
	margin: 13px 0 0 0;
}
.modal-body {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em ;
    min-height: 8.75em;
}
.modal-header {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif';
	font-size: 0.875em ;
    background-color: #00AA7E;
	color: #FFFFFF;
	height: 4em;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1.5rem -1rem -1rem auto;
    color: #FFFFFF;
}
td.tdhead {
    font-weight: 600;
}

/*customerActivationSerial*/

.customerAdvancedActivation{
margin-top:3% !important;
}

.fontWeight{
font-weight: bold
}

.generateSiteKeyFont{
margin-top:7% !important;
}
/*SoftwareActivationModalPop*/
.softwareActivation{
margin-top:2% !important;
}

/*Error tag*/
.fontInfo{

	font-size:148% !important;
}

.error-btn-margin{

	margin-top:25% !important;
}
.fontAnchor{
	    font-size: 90% !important;
}